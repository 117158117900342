<template>
  <div>
    <h1>Hybrid transactional/analytical processing Database</h1>
    <p>
      <a href="https://en.wikipedia.org/wiki/Hybrid_transactional/analytical_processing" target="_blank" rel="noopener">wiki</a>
    </p>
    <h3>Players</h3>
    <ul>
      <li><a href="https://cloud.google.com/spanner" target="_blank" rel="noopener">Cloud Spanner</a></li>
      <li><a href="https://tidb.net" target="_blank" rel="noopener">TiDB</a></li>
      <li><a href="https://dbdb.io/db/hyper" target="_blank" rel="noopener">HyPer</a></li>
      <li><a href="https://www.yugabyte.com" target="_blank" rel="noopener">YugabyteDB</a></li>
      <li>....</li>
    </ul>
    <p class="footer">contact: <a href="mailto:archmage.w@foxmail.com">archmage.w@foxmail.com</a></p>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.footer {
  margin-top: 5rem;
}
</style>
